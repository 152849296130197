@import './variable';

.first-section {
  header {
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 9;

    .header-menu__wrapper__list a:hover {
      color: $secondaryColor;
    }

    .back-home-btn {
      color: #fff;
      font-size: 14px;
      display: inline-flex;
      align-items: center;
      font-family: 'Poppins', sans-serif;

      @include respond-below(sm) {
        background-color: $secondaryColor;
        padding: 6px 12px;
        border-radius: 5px;
      }

      svg {
        margin-right: 10px;

        path {
          transition: all 0.2s ease;
        }
      }

      &:hover {
        color: $primaryColor;

        svg path {
          fill: $primaryColor;
        }
      }
    }
  }

  .row {
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__content {
    display: flex;

    @include respond-below(sm) {
      flex-direction: column;
    }

    &__left {
      background-color: $lightBgColor;
      max-width: 50%;
      flex-basis: 50%;
      min-height: 100vh;
      padding: 120px 90px;
      display: flex;
      align-items: center;

      @include respond-below(md) {
        padding: 50px;
      }

      @include respond-below(sm) {
        max-width: 100%;
        flex-basis: 100%;
        min-height: 0;
        padding: 100px 20px 40px;
      }

      &__inner {
        width: 100%;
        max-width: 350px;
        margin-left: auto;

        @include respond-below(sm) {
          max-width: 100%;
        }

        h2 {
          margin-bottom: 20px;
        }

        .reset-pass-message {
          display: none;
          margin-top: 20px;

          &.active {
            display: block;
          }

          p {
            font-size: 14px;
            font-weight: 600;
            line-height: 1.5;
          }
        }

        @include password-group() {
          input {
            padding-right: 40px !important;
          }
        }

        &__signup-links {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;

          .btn-custom {
            padding: 10px 39px;
            margin-bottom: 15px;

            &.btn-signup {
              margin-bottom: 0;
            }

            &.reset-pass-btn {
              margin-top: 10px;
            }

            &.resend-pass-btn {
              display: none;
            }
            &.resend-pass-btn.active {
              display: block;
            }
          }

          &__links {
            &.reset-pass-links {
              margin-top: 40px;
            }

            p {
              margin-bottom: 0px;
              font-size: 14px;
            }

            a {
              color: #333333;
              font-size: 14px;
            }
          }
        }

        &__social-login {
          display: flex;
          flex-direction: column;

          a {
            background: #fff;
            margin-bottom: 15px;
            display: flex;
            padding: 10px 15px;
            border-radius: 5px;

            svg {
              width: 22px;
            }

            span {
              display: inline-block;
              margin-left: 15px;
              padding-left: 15px;
              border-left: 1px solid #efe1ed;
            }
          }
        }
      }
    }

    &__right {
      max-width: 50%;
      flex-basis: 50%;
      display: flex;
      flex-direction: column;
      min-height: 100vh;

      @include respond-below(sm) {
        max-width: 100%;
        flex-basis: 100%;
        min-height: 0;
      }

      &__top {
        background: $secondaryColor;
        padding: 120px 90px 50px;
        flex-basis: 60%;
        display: flex;
        align-items: center;

        @include respond-below(md) {
          padding: 100px 50px 30px;
        }
        @include respond-below(sm) {
          padding: 40px 20px;
        }

        &__inner {
          max-width: 445px;
        }

        &__stars {
          margin-bottom: 20px;
        }

        &__women {
          width: 96px;
          height: 96px;
          margin-bottom: 20px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        h3 {
          font-family: 'Josefin Sans' !important;
          font-size: 27px !important;
          color: #fff !important;
          font-weight: 400 !important;
        }

        p {
          line-height: 1.5;
          color: #fff;
        }
      }

      &__bottom {
        background: $primaryColor;
        flex-basis: 40%;
        display: flex;
        align-items: center;

        &__inner {
          position: relative;
          padding: 50px 150px 50px 90px;
          max-width: 720px;

          @include respond-below(md) {
            padding: 30px 50px;
          }
          @include respond-below(sm) {
            padding: 40px 100px 40px 20px;
          }

          &::before {
            position: absolute;
            content: '';
            width: 170px;
            height: 1000px;
            background-size: contain;
            background-image: url(/frontend/images/women-register-thumbs.png);
            background-repeat: no-repeat;
            background-position: top right;
            top: 20%;
            right: -60px;
            pointer-events: none;
          }

          h3 {
            color: #fff;
            font-size: 22px !important;
            margin-bottom: 20px;
          }
          ul li {
            color: #fff;
            position: relative;
            padding-left: 30px;
            margin-bottom: 5px;

            &::before {
              position: absolute;
              content: '';
              left: 0;
              top: 2px;
              background: url('/frontend/images/icons/checkmark-white.svg');
              background-size: 100% 100%;
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
  }
}
