.first-section header {
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 9;
}
.first-section header .header-menu__wrapper__list a:hover {
  color: #732f6f;
}
.first-section header .back-home-btn {
  color: #fff;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767px) {
  .first-section header .back-home-btn {
    background-color: #732f6f;
    padding: 6px 12px;
    border-radius: 5px;
  }
}
.first-section header .back-home-btn svg {
  margin-right: 10px;
}
.first-section header .back-home-btn svg path {
  transition: all 0.2s ease;
}
.first-section header .back-home-btn:hover {
  color: #f79837;
}
.first-section header .back-home-btn:hover svg path {
  fill: #f79837;
}
.first-section .row {
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.first-section__content {
  display: flex;
}
@media (max-width: 767px) {
  .first-section__content {
    flex-direction: column;
  }
}
.first-section__content__left {
  background-color: #fef7fd;
  max-width: 50%;
  flex-basis: 50%;
  min-height: 100vh;
  padding: 120px 90px;
  display: flex;
  align-items: center;
}
@media (max-width: 991px) {
  .first-section__content__left {
    padding: 50px;
  }
}
@media (max-width: 767px) {
  .first-section__content__left {
    max-width: 100%;
    flex-basis: 100%;
    min-height: 0;
    padding: 100px 20px 40px;
  }
}
.first-section__content__left__inner {
  width: 100%;
  max-width: 350px;
  margin-left: auto;
}
@media (max-width: 767px) {
  .first-section__content__left__inner {
    max-width: 100%;
  }
}
.first-section__content__left__inner h2 {
  margin-bottom: 20px;
}
.first-section__content__left__inner .reset-pass-message {
  display: none;
  margin-top: 20px;
}
.first-section__content__left__inner .reset-pass-message.active {
  display: block;
}
.first-section__content__left__inner .reset-pass-message p {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
}
.first-section__content__left__inner .form-group-password {
  position: relative;
}
.first-section__content__left__inner .form-group-password input {
  padding-right: 40px !important;
}
.first-section__content__left__inner .form-group-password .password-showhide {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.first-section__content__left__inner__signup-links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.first-section__content__left__inner__signup-links .btn-custom {
  padding: 10px 39px;
  margin-bottom: 15px;
}
.first-section__content__left__inner__signup-links .btn-custom.btn-signup {
  margin-bottom: 0;
}
.first-section__content__left__inner__signup-links .btn-custom.reset-pass-btn {
  margin-top: 10px;
}
.first-section__content__left__inner__signup-links .btn-custom.resend-pass-btn {
  display: none;
}
.first-section__content__left__inner__signup-links .btn-custom.resend-pass-btn.active {
  display: block;
}
.first-section__content__left__inner__signup-links__links.reset-pass-links {
  margin-top: 40px;
}
.first-section__content__left__inner__signup-links__links p {
  margin-bottom: 0px;
  font-size: 14px;
}
.first-section__content__left__inner__signup-links__links a {
  color: #333333;
  font-size: 14px;
}
.first-section__content__left__inner__social-login {
  display: flex;
  flex-direction: column;
}
.first-section__content__left__inner__social-login a {
  background: #fff;
  margin-bottom: 15px;
  display: flex;
  padding: 10px 15px;
  border-radius: 5px;
}
.first-section__content__left__inner__social-login a svg {
  width: 22px;
}
.first-section__content__left__inner__social-login a span {
  display: inline-block;
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid #efe1ed;
}
.first-section__content__right {
  max-width: 50%;
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
@media (max-width: 767px) {
  .first-section__content__right {
    max-width: 100%;
    flex-basis: 100%;
    min-height: 0;
  }
}
.first-section__content__right__top {
  background: #732f6f;
  padding: 120px 90px 50px;
  flex-basis: 60%;
  display: flex;
  align-items: center;
}
@media (max-width: 991px) {
  .first-section__content__right__top {
    padding: 100px 50px 30px;
  }
}
@media (max-width: 767px) {
  .first-section__content__right__top {
    padding: 40px 20px;
  }
}
.first-section__content__right__top__inner {
  max-width: 445px;
}
.first-section__content__right__top__stars {
  margin-bottom: 20px;
}
.first-section__content__right__top__women {
  width: 96px;
  height: 96px;
  margin-bottom: 20px;
}
.first-section__content__right__top__women img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.first-section__content__right__top h3 {
  font-family: "Josefin Sans" !important;
  font-size: 27px !important;
  color: #fff !important;
  font-weight: 400 !important;
}
.first-section__content__right__top p {
  line-height: 1.5;
  color: #fff;
}
.first-section__content__right__bottom {
  background: #f79837;
  flex-basis: 40%;
  display: flex;
  align-items: center;
}
.first-section__content__right__bottom__inner {
  position: relative;
  padding: 50px 150px 50px 90px;
  max-width: 720px;
}
@media (max-width: 991px) {
  .first-section__content__right__bottom__inner {
    padding: 30px 50px;
  }
}
@media (max-width: 767px) {
  .first-section__content__right__bottom__inner {
    padding: 40px 100px 40px 20px;
  }
}
.first-section__content__right__bottom__inner::before {
  position: absolute;
  content: "";
  width: 170px;
  height: 1000px;
  background-size: contain;
  background-image: url(/frontend/images/women-register-thumbs.png);
  background-repeat: no-repeat;
  background-position: top right;
  top: 20%;
  right: -60px;
  pointer-events: none;
}
.first-section__content__right__bottom__inner h3 {
  color: #fff;
  font-size: 22px !important;
  margin-bottom: 20px;
}
.first-section__content__right__bottom__inner ul li {
  color: #fff;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
}
.first-section__content__right__bottom__inner ul li::before {
  position: absolute;
  content: "";
  left: 0;
  top: 2px;
  background: url("/frontend/images/icons/checkmark-white.svg");
  background-size: 100% 100%;
  width: 18px;
  height: 18px;
}